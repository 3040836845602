/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  createDialog: {
    width: '500px',
    minHeight: '300px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '50px',
    paddingRight: '50px',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '20px',
    justifyContent: 'center',
  },
  dialogContentBody: {
    display: 'flex',
    fontWeight: 500,
    minHeight: '170px',
    fontSize: '0.875rem',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogActionGroup: {
    marginTop: '40px',
    marginBottom: '40px',
    height: '40px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  cancelButton: {
    flex: 1,
    border: '2px solid #000000',
    borderRadius: '20px',
    marginRight: '30px',
    maxWidth: '50%',
    color: '#000',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  confirmButton: {
    flex: 1,
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: '#000000',
    },
    maxWidth: '50%',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
});

export default function GlobalDialog({
  open,
  onClose,
  closeIcon,
  cancelText,
  onCancel,
  confirmText,
  onConfirm,
  dialogContent,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <div className={classes.createDialog}>
        {closeIcon
          && (
            <div
              className={classes.closeIcon}
              onClick={onCancel}
            >
              <CloseIcon />
            </div>
          )}
        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialogContentBody}>
            {dialogContent}
          </div>
          <div className={classes.dialogActionGroup}>
            {cancelText && (
              <Button
                className={classes.cancelButton}
                onClick={onCancel}
                color="secondary"
              >
                {cancelText}
              </Button>
            )}
            {confirmText && (
              <Button
                className={classes.confirmButton}
                onClick={onConfirm}
                color="primary"
                variant="outlined"
              >
                {confirmText}
              </Button>
            )}
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
}

GlobalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeIcon: PropTypes.bool,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  dialogContent: PropTypes.element.isRequired,
};

GlobalDialog.defaultProps = {
  closeIcon: false,
  cancelText: 'Cancel',
  confirmText: 'Confirm',
};
