import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Router from 'routes';

const useStyles = makeStyles({
  app: {
    margin: '0px auto',
    backgroundColor: '#E5E5E5',
    width: '100vw',
    height: '100vh',
  },
});

function App() {
  const classes = useStyles();

  return (
    <div className={classes.app}>
      <Router />
    </div>
  );
}

export default App;
