import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { STATUS_COLOR_MAP, CAMPAIGN_STATUS_MAP_CAP } from 'modules/campaign/constants';

const useStyles = makeStyles({
  statusLabel: {
    borderRadius: '20px',
    backgroundColor: (props) => STATUS_COLOR_MAP[props.label],
    height: '20x',
    marginRight: '20px',
  },
});

export default function StatusLabel({ label }) {
  const classes = useStyles({ label });

  return (
    <Typography
      className={classes.statusLabel}
      variant="body2"
      align="center"
    >
      {CAMPAIGN_STATUS_MAP_CAP[label]}
    </Typography>
  );
}
