import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Popover,
  Typography,
} from '@material-ui/core';
import { ROUTE_PATH } from 'constants/index';
import { storage, STORAGE_KEY } from '../../util';
import packageJson from '../../../package.json';

const useStyles = makeStyles({
  headerBar: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignContent: 'center',
  },
  logoContainer: {
    minWidth: '100px',
  },
  logo: {
    marginLeft: '20px',
    height: '100%',
    width: '100%',
    maxHeight: '30px',
    maxWidth: '130px',
  },
  userNameContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  userName: {
    textDecoration: 'underline',
  },
  versionName: {
    color: '#000000',
  },
  logoutPop: {
    padding: '15px',
    height: '95px',
    width: '175px',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'center',
  },
  logoutButton: {
    marginTop: '10px',
    borderRadius: '30px',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
});

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  const { userName } = storage.get(STORAGE_KEY.USERINFO, true);

  const [userInfoPopAnchor, setUserInfoPopAnchor] = useState(null);

  const showUserInfoPop = (event) => {
    setUserInfoPopAnchor(event.currentTarget);
  };

  const hideUserInfoPop = () => {
    setUserInfoPopAnchor(null);
  };

  const logout = () => {
    storage.clear();
    history.push(ROUTE_PATH.LOGIN);
  };

  const open = Boolean(userInfoPopAnchor);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid container className={classes.headerBar} spacing={3}>
      <Grid item xs={2} className={classes.logoContainer}>
        <img className={classes.logo} src="/burberry_logo.svg" alt="Burberry" />
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h1" color="primary">
          Web Portal
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {userName ? (
          <div className={classes.userNameContainer}>
            <Button onClick={showUserInfoPop}>
              <Typography className={classes.userName}>{userName}</Typography>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={userInfoPopAnchor}
              onClose={hideUserInfoPop}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={classes.logoutPop}>
                <Typography variant="body1">
                  {`You currently login as ${userName}`}
                </Typography>
                <Button
                  className={classes.logoutButton}
                  color="primary"
                  variant="contained"
                  onClick={logout}
                >
                  Logout
                </Button>
              </div>
            </Popover>
          </div>
        )
          : (
            <div className={classes.userNameContainer}>
              <Typography className={classes.versionName}>
                {`v ${packageJson.version}`}
              </Typography>
            </div>
          )}
      </Grid>
    </Grid>
  );
}
