/* eslint-disable func-names */
/* eslint-disable object-shorthand */
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider, withSnackbar } from 'notistack';
import theme from 'styles/theme';

export default {
  success: function (msg) {
    this.toast(msg, 'success');
  },
  warning: function (msg) {
    this.toast(msg, 'warning');
  },
  error: function (msg) {
    this.toast(msg, 'error');
  },
  info: function (msg) {
    this.toast(msg, 'info');
  },
  toast: function (msg, variant) {
    const Display = withSnackbar(({ message, enqueueSnackbar }) => {
      enqueueSnackbar(message, { variant });
      return null;
    });
    const mountPoint = document.getElementById('toastHelper');
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          hideIconVariant
          preventDuplicate
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Display message={msg} variant={variant} />
        </SnackbarProvider>
      </ThemeProvider>,
      mountPoint,
    );
  },
};
