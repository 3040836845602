import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import LoadMoreButton from '../loadmoreButton';
import UploadButton from './uploadButton';
import AssetGrid from './assetGrid';
import LoadMoreMgr from '../loadmore.util';

const useStyles = makeStyles({
  assetsGrid: {
    maxHeight: '100%',
    padding: '20px',
  },
  mediaUpload: {
    margin: '10px auto',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  assetDesc: {
    marginBottom: '10px',
  },
});

const assetsLoadMoreMgr = new LoadMoreMgr([], { pageSize: 36 });

const AssetsGroup = ({ group, campaignId }) => {
  const classes = useStyles();
  const [assets, setAssets] = useState({ data: [] });
  useEffect(() => {
    assetsLoadMoreMgr.setData(group.assets);
    setAssets(assetsLoadMoreMgr.first());
  }, [group]);

  return (
    <div className={classes.assetsGrid}>
      <div className={classes.mediaUpload}>
        <Typography variant="body2" className={classes.assetDesc}>
          Campaign Assets Description: Serious campaign shots involves Model shots,
          stage shots from the campaign.
          Recommend number of assets to be featured says here.
        </Typography>
        <UploadButton
          id={campaignId}
          assetGroupName={group.groupName}
        />
      </div>
      <AssetGrid assets={assets.data} />
      {assets.hasNext && (
        <LoadMoreButton
          variant="text"
          color="primary"
          onClick={() => { setAssets(assetsLoadMoreMgr.next()); }}
        >
          load more
        </LoadMoreButton>
      )}
    </div>
  );
};

export default AssetsGroup;
