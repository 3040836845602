/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { GlobalDialog } from 'components/common';
import { SERVICE_STATUS_MAP } from 'modules/campaign/constants';
import { updateCampaignStatus } from 'modules/campaign/campaign.services';
import { emitter, EMITTER_KEY, load } from '../../../util';

const useStyles = makeStyles({
  statusButton: {
    marginTop: '67px',
  },
  context: {
    marginTop: '10px',
    color: '#565656',
  },
});

const BUTTON_STATUS_TEXT_MAP = {
  published: 'Expire the folder',
  wip: 'Publish to WeChat Work',
  expired: 'Undo Expiration',
};

const CONTEXT = {
  published: 'When majority assets in the folder is outdated or without usage right. It is required to set the folder as expired. When a folder is expired, it will set the campaign folder invisible on the SA side.',
  wip: 'Once you publish to WeChat Work, all assets will be visible to SA. Same time, you will still be able to add new assets or delete after publishing. ',
  expired: 'When majority assets in the folder is outdated or without usage right. It is required to set the folder as expired. When a folder is expired, it will set the campaign folder invisible on the SA side.',
};

const DIALOG_CONTEXT = {
  published: 'Are you sure you want to set the folder to expired? Once set as expired, it will hide from WeChat Work and SA will not be able to access or download.',
  wip: 'Are you sure you want to publish this campaign folder to WeChat Work?',
  expired: 'Are you sure you want to revert the folder to work in progress?',
};

const nextStatus = {
  wip: SERVICE_STATUS_MAP.PUBLISHED,
  published: SERVICE_STATUS_MAP.EXPIRED,
  expired: SERVICE_STATUS_MAP.WIP,
};

export default function StatusButton({
  campaignId,
  status,
  assetsCount,
}) {
  const classes = useStyles();
  const [openConformDialog, setOpenConformDialog] = useState(false);

  const showConformDialog = () => {
    setOpenConformDialog(true);
  };

  const closeConformDialog = () => {
    setOpenConformDialog(false);
  };

  const dialogContent = (
    <div>
      {DIALOG_CONTEXT[status]}
    </div>
  );

  const handleStatusUpdate = async () => {
    if (status === SERVICE_STATUS_MAP.WIP && assetsCount === 0) return;

    setOpenConformDialog(false);
    load.open();
    const result = await updateCampaignStatus(campaignId, nextStatus[status]);
    if (result) {
      emitter.emit(EMITTER_KEY.UPDATE_ASSET_STATUS_DONE);
    }
  };

  return (
    <div className={classes.statusButton}>
      <Button
        color={status === SERVICE_STATUS_MAP.WIP ? 'secondary' : 'default'}
        variant="outlined"
        component="span"
        onClick={showConformDialog}
        disabled={status === SERVICE_STATUS_MAP.WIP && assetsCount === 0}
      >
        {BUTTON_STATUS_TEXT_MAP[status]}
      </Button>
      <Typography variant="body2" className={classes.context}>
        {CONTEXT[status]}
      </Typography>
      <GlobalDialog
        open={openConformDialog}
        onClose={closeConformDialog}
        closeIcon
        dialogContent={dialogContent}
        cancelText="Cancel"
        onCancel={closeConformDialog}
        confirmText="Yes"
        onConfirm={handleStatusUpdate}
      />
    </div>
  );
}

StatusButton.propTypes = {
  campaignId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  assetsCount: PropTypes.number.isRequired,
};
