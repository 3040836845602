/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import dateFormat from 'dateformat';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import CampaignCover from 'modules/campaign/campaignList/campaignTable/campaignCover';
import StatusLabel from './statusLabel';

const useStyles = makeStyles({
  campaignRow: {
    maxHeight: '100px',
    cursor: 'pointer',
  },
  campaignCell: {
    padding: '0px 20px 0px 30px',
  },
});

export default function CampaignRow({ campaign, campaignNo }) {
  const classes = useStyles();
  const history = useHistory();

  const flatCampaign = (campaign) => [
    <Typography>{campaignNo}</Typography>,
    <CampaignCover assetsLibrary={campaign} />,
    <Typography>{campaign.title + campaign.subtitle}</Typography>,
    <Typography>
      {`Campaign Assets: ${campaign.campaignCount}`}
      <br />
      {`PR Assets: ${campaign.prCount}`}
    </Typography>,
    <Typography>{campaign.userName}</Typography>,
    <Typography>{dateFormat(campaign.createdAt, 'mmm dd, yyyy')}</Typography>,
    <StatusLabel label={campaign.status} />,
  ];

  const navToCampaignDetail = () => {
    history.push(`/campaign/${campaign.id}`);
  };

  return (
    <TableRow
      key={campaign.name}
      className={classes.campaignRow}
      onClick={navToCampaignDetail}
    >
      {flatCampaign(campaign).map((campaignItem, index) => (
        <TableCell
          align={index === 0 ? 'right' : 'left'}
          classes={{ root: classes.campaignCell }}
          children={campaignItem}
          key={`campaignCell-${index}`}
        />
      ))}
    </TableRow>
  );
}
