import SideNav from './sideNav';
import MainContent from './mainContent';
import { ROUTE_PATH } from '../../../constants';

export default {
  public: false,
  path: ROUTE_PATH.CAMPAIGNS,
  SideNav,
  MainContent,
};
