import { EventEmitter } from 'events';

export default new EventEmitter();

export const EMITTER_KEY = {
  UPLOAD_START: 'upload_start',
  UPLOAD_END: 'upload_end',
  UPLOAD_INPROGRESS: 'upload_inprogress',
  UPLOAD_ERROR: 'upload_error',
  UPDATE_ASSET_STATUS_START: 'update_asset_status_start',
  UPDATE_ASSET_STATUS_DONE: 'update_asset_status_done',
  LIST_REFRESH_DONE: 'list_refresh_done',
  DETAIL_REFRESH_DONE: 'detail_refresh_done',
  DELETE_ASSET_DONE: 'delete_asset_done',
};
