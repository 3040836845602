import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import CreateButton from './createCampaign';
import { emitter, EMITTER_KEY } from '../../../util';

const useStyles = makeStyles({
  statusOverview: {
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
});

const CAMPAIGN_STATUS_MAP = [
  {
    key: 'published',
    text: 'Published',
  },
  {
    key: 'wip',
    text: 'In Progress',
  },
  {
    key: 'expired',
    text: 'Expired',
  },
];

export default function SideNav() {
  const classes = useStyles();
  const [statusOverview, setStatusOverview] = useState({});

  const getStatusOverview = (overview) => {
    setStatusOverview(overview);
  };

  useEffect(() => {
    emitter.addListener(EMITTER_KEY.LIST_REFRESH_DONE, getStatusOverview);

    return () => {
      emitter.removeListener(EMITTER_KEY.LIST_REFRESH_DONE, getStatusOverview);
    };
  }, []);

  return (
    <Container>
      <div className={classes.statusOverview}>
        <Typography variant="h2">Status Overview</Typography>
        {Object.keys(statusOverview).length > 0 && CAMPAIGN_STATUS_MAP.map((item) => (
          <Typography variant="body1" key={item.key}>
            {`${item.text}: ${statusOverview[item.key]}`}
          </Typography>
        ))}
      </div>
      <CreateButton />
    </Container>
  );
}
