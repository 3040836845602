import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  content: {
    paddingLeft: '30px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    paddingTop: '30px',
  },
});

export default function MainContent({ children }) {
  const classes = useStyles();

  return (
    <Grid item className={classes.content}>
      {children}
    </Grid>
  );
}
