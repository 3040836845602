import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';

const useStyles = makeStyles({
  sideNavContainer: {
    flex: 1,
    borderRight: '1px solid #C4C4C4',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '30px',
  },
});

export default function SideNavigation({ children }) {
  const classes = useStyles();

  return (
    <Hidden mdDown>
      <Grid className={classes.sideNavContainer} item xs={2}>
        {children}
      </Grid>
    </Hidden>
  );
}
