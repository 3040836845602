import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Typography } from '@material-ui/core';
import dateFormat from 'dateformat';

const useStyles = makeStyles({
  cover: {
    display: 'flex',
    flexDirection: 'column',
    height: '90px',
    backgroundSize: 'cover',
  },
  coverText: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'left',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  title: {
    fontSize: '12pt',
    color: '#FFFFFF',
    fontWeight: '500',
    marginLeft: '25px',
  },
  updatedTime: {
    fontSize: '8pt',
    color: '#FFFFFF',
    marginTop: '5px',
    marginLeft: '25px',
  },
  badgeRoot: {
    paddingRight: '10px',
  },
  badge: {
    backgroundColor: 'red',
  },
});
export default function CampaignCover({ assetsLibrary }) {
  const classes = useStyles();
  const { coverImageUrl, updatedAt, title } = assetsLibrary;
  const formattedTime = dateFormat(updatedAt, 'mmm dd, yyyy');
  const date = new Date();
  const currentDate = date.getTime();
  // Show the red badge if the last updated is less than 7 days
  const gapDay = Math.floor((currentDate - updatedAt) / 86400000);
  const invisible = gapDay > 7;

  return (
    <div
      className={classes.cover}
      style={{ backgroundImage: `url(/${coverImageUrl})` }}
    >
      <div className={classes.coverText}>
        <div>
          <Badge
            classes={{
              root: classes.badgeRoot,
              badge: classes.badge,
            }}
            color="error"
            variant="dot"
            invisible={invisible}
          >
            <Typography className={classes.title}>
              {title}
            </Typography>
          </Badge>
        </div>
        <Typography className={classes.updatedTime}>
          Last updated
          {' '}
          {formattedTime}
        </Typography>
      </div>
    </div>
  );
}
