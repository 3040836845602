/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button, DialogContentText, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { GlobalDialog } from 'components/common';
import { createCampaign } from 'modules/campaign/campaign.services';
import { useHistory } from 'react-router-dom';
import { load } from '../../../../util';

const useStyles = makeStyles({
  createButton: {
    height: '45px',
    backgroundColor: '#FFFFFF',
    borderRadius: '25px',
  },
  addIcon: {
    marginRight: '8px',
  },
  createDialog: {
    width: '500px',
    height: '300px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
  },
  dialogContent: {
    textAlign: 'left',
    width: '100%',
  },
  campaignInput: {
    marginTop: '20px',
    marginBottom: '5px',
    padding: '10px',
    height: '45px',
    border: '2px solid #000000',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function CreateCampaign() {
  const classes = useStyles();
  const history = useHistory();
  const [openCreationDialog, setOpenCreationDialog] = useState(false);
  const [campaign, setCampaign] = useState({ title: '' });

  const showCreationDialog = () => {
    setOpenCreationDialog(true);
  };

  const closeCreationDialog = () => {
    setCampaign({ title: '' });
    setOpenCreationDialog(false);
  };

  // TODO: validate input;
  const validateNewCampaign = () => Boolean(campaign.title);

  const submit = async () => {
    if (validateNewCampaign()) {
      setOpenCreationDialog(false);
      load.open();
      const createdCampaign = await createCampaign(campaign);
      if (createdCampaign) history.push(`/campaign/${createdCampaign.id}`);
    }
  };

  const handleNewCampaignTitleInput = (event) => {
    setCampaign({
      ...campaign,
      title: event.target.value,
    });
  };

  const dialogContent = (
    <div className={classes.dialogContent}>
      <Typography variant="h2">
        New Campaign Folder
      </Typography>
      <div className={classes.campaignInput}>
        <TextField
          autoFocus
          InputProps={{ disableUnderline: true }}
          value={campaign.title}
          onChange={handleNewCampaignTitleInput}
          margin="dense"
          size="medium"
          fullWidth
        />
      </div>
      <DialogContentText>
        Max 10 CN characters / 20 EN characters
      </DialogContentText>
    </div>
  );

  return (
    <div>
      <Button
        className={classes.createButton}
        fullWidth
        color="primary"
        onClick={showCreationDialog}
      >
        <AddIcon className={classes.addIcon} />
        <Typography variant="button">Create</Typography>
      </Button>
      <GlobalDialog
        open={openCreationDialog}
        onClose={closeCreationDialog}
        closeIcon
        dialogContent={dialogContent}
        cancelText="Cancel"
        onCancel={closeCreationDialog}
        confirmText="Create"
        onConfirm={submit}
      />
    </div>
  );
}
