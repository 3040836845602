/* eslint-disable react/no-children-prop */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Grid } from '@material-ui/core';
import Header from './header';
import SideNavContainer from './sideNavContainer';
import MainContentContainer from './mainContentContainer';

const useStyles = makeStyles({
  pageGrid: {
    margin: '0px auto',
    maxWidth: '1500px',
    minWidth: '1024px',
    minHeight: '700px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerBarContainer: {
    position: 'static',
    height: '5vh',
    minHeight: '60px',
    background: 'none',
    boxShadow: 'none',
    borderBottom: '1px solid #C4C4C4',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  bodyContainer: {
    height: '95vh',
    paddingTop: '20px',
    paddingBottom: '40px',
  },
});

export default function DefaultLayout({ userName, sideNav, mainContent }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.pageGrid}>
      <AppBar className={classes.headerBarContainer}>
        <Header userName={userName} />
      </AppBar>
      <Grid item container className={classes.bodyContainer}>
        {sideNav && <SideNavContainer children={sideNav()} />}
        <MainContentContainer children={mainContent()} />
      </Grid>
      <div id="loadHelper" />
      <div id="toastHelper" />
    </Grid>
  );
}
