/* eslint-disable no-console */
import axios from 'axios';
import toast from './toast';
import load from './load';
import help from './help';

const http = axios.create({
  withCredentials: true,
  headers: {
    'content-type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

http.interceptors.request.use((config) => {
  let { url } = config;
  const token = help.getStorageToken();

  if (process.env.NODE_ENV === 'production') {
    url = url.replace('/api', process.env.REACT_APP_API_DOMAIN);
  }

  if (token) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${token}`,
    });
  }

  return Object.assign(config, { url });
}, (error) => Promise.reject(error));

http.interceptors.response.use(
  (response) => {
    const body = response.data;
    if (body.success) {
      return body.data;
    }

    toast.error(body.message);
    load.close();
    return null;
  },
  (error) => {
    const { response } = error;
    let message = '';
    if (response) {
      message = (response.data && response.data.message);
    }
    toast.error(message);
    load.close();
    return Promise.reject(error);
  },
);

export default http;
