import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
  GridList,
  GridListTile,
  Typography,
} from '@material-ui/core';
import AssetPreviewLightBox from './assetPreviewLightBox';
import { emitter, EMITTER_KEY } from '../../../util';

const useStyles = makeStyles({
  card: {
    position: 'relative',
    borderRadius: 0,
  },
  cardMediaStyle: {
    height: 100,
    border: 'none',
    shadow: 'none',
    backgroundColor: '#D3C4C4',
  },
  duration: {
    position: 'absolute',
    right: 10,
    bottom: 0,
    color: '#fff',
  },
});

const AssetGrid = ({ assets }) => {
  const classes = useStyles();

  const [previewConfig, setPreviewConfig] = useState({
    open: false,
    current: 0,
  });
  const preview = (event) => {
    const current = event.currentTarget.dataset.index * 1;
    setPreviewConfig({ open: true, current });
  };

  const closePreview = (deletedStatus) => {
    if (deletedStatus) {
      emitter.emit(EMITTER_KEY.DELETE_ASSET_DONE);
    }
    setPreviewConfig({ open: false });
  };
  return (
    <>
      <GridList cellHeight={100} cols={12} spacing={3}>
        {assets.length > 0 && assets.map((asset, idx) => (
          <GridListTile key={asset.id} cols={asset.cols || 1} data-index={idx} onClick={preview}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMediaStyle}
                image={asset.materialThumbUrl}
              />
              {asset.duration && (
                <Typography variant="caption" className={classes.duration}>{asset.duration}</Typography>
              )}
            </Card>
          </GridListTile>
        ))}
      </GridList>
      {previewConfig.open && (
        <AssetPreviewLightBox
          showResourceCount
          data={assets}
          onClose={closePreview}
          startIndex={previewConfig.current}
        />
      )}
    </>
  );
};

export default AssetGrid;
