function paginate(array, { pageSize, pageNumber }) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}
export default class LoadMoreMgr {
  constructor(data, pageInfo) {
    this.data = data;
    this.pageInfo = pageInfo;
    this.history = [];
  }

  setData(data) {
    this.data = data;
    const pageCount = Math.ceil(data.length / this.pageInfo.pageSize);
    this.pageInfo = {
      ...this.pageInfo,
      pageNumber: 1,
      pageCount,
    };
    this.history = [];
  }

  hasNext() {
    return this.pageInfo.pageNumber < this.pageInfo.pageCount;
  }

  first() {
    this.pageInfo.pageNumber = 1;
    const data = paginate(this.data, this.pageInfo);
    this.history = data;
    return {
      data,
      hasNext: this.hasNext(),
    };
  }

  next() {
    this.pageInfo.pageNumber += 1;
    const data = [...this.history, ...paginate(this.data, this.pageInfo)];
    this.history = data;
    return {
      data,
      hasNext: this.hasNext(),
    };
  }
}
