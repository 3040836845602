/* eslint-disable no-undef */
import { http } from 'util/index';
import { API } from './constants';

async function getCampaignListByStatus(status) {
  const result = await http.post(API.POST.SEARCH, {
    status,
  });

  if (!result) return null;
  return result.data;
}

async function getCampaignsStatusOverview() {
  const result = await http.get(API.GET.COUNT);
  return result;
}

async function createThumbnail(srcKey) {
  return http.put(API.PUT.THUMBNAIL, { srcKey });
}

async function createCampaign(campaign) {
  const createdCampaign = await http.post(API.POST.CREATE, { title: campaign.title });
  return createdCampaign;
}

async function getCampaignById(campaignId) {
  const url = API.GET.DETAIL.replace(':id', campaignId);
  const result = await http.get(url, campaignId);
  return result;
}

async function getUploadSignedUrl(id, assetGroupName, filename) {
  const result = await http.put(
    API.PUT.UPLOAD,
    {
      id,
      assetGroupName,
      filename,
    },
  );
  return result;
}

async function updateCampaign(id, params) {
  const url = API.PUT.UPDATE_ASSET.replace(':id', id);
  const bodyFormData = new FormData();
  params.forEach((param) => {
    bodyFormData.append(param.key, param.value);
  });
  const result = await http.put(url, bodyFormData);
  return result;
}

async function updateCampaignStatus(id, status) {
  const params = [{ key: 'status', value: status }];
  const result = await updateCampaign(id, params);
  return result;
}

async function updateCampaignTitle(id, title) {
  const params = [{ key: 'title', value: title }];
  const result = await updateCampaign(id, params);
  return result;
}

async function deleteCampaign(id) {
  const ids = typeof (id) === 'string' ? [id] : id;
  const result = await http.delete(API.DELETE.DELETE_CAMPAIGN, {
    data: { ids },
  });
  return result;
}

async function deleteAsset(key) {
  const keys = typeof (key) === 'string' ? [key] : key;
  const result = await http.delete(API.DELETE.DELETE_ASSET, {
    data: { keys },
  });
  return result;
}

async function updateCampaignCoverImage(id, coverImage) {
  const params = [{ key: 'coverImage', value: coverImage }];
  const result = await updateCampaign(id, params);
  return result;
}

export {
  getCampaignListByStatus,
  getCampaignsStatusOverview,
  createThumbnail,
  createCampaign,
  getUploadSignedUrl,
  updateCampaignStatus,
  updateCampaignTitle,
  updateCampaignCoverImage,
  getCampaignById,
  deleteCampaign,
  deleteAsset,
};
