/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { GlobalDialog } from 'components/common';
import { deleteCampaign } from 'modules/campaign/campaign.services';
import { ROUTE_PATH } from '../../../constants';
import { load } from '../../../util';

const useStyles = makeStyles({
  deleteButtonContainer: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    padding: 'inherit',
    marginBottom: '10px',
  },
  deleteButton: {
    textDecoration: 'underline',
    padding: '0',
    fontSize: '1.0rem',
    fontWeight: '550',
    lineHeight: '1.167',
  },
  context: {
    marginTop: '10px',
    color: '#565656',
  },
});

const CONTEXT = 'Delete the folder will remove all info, assets and historical actions';

export default function DeleteButton({ campaignId }) {
  const history = useHistory();
  const classes = useStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const showDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCampaignDelete = async () => {
    setOpenDeleteDialog(false);
    load.open();
    await deleteCampaign(campaignId);
    history.push(ROUTE_PATH.CAMPAIGNS);
  };

  const dialogContent = (
    <div>
      Are you sure you want to delete the folder?
      Once delete will remove all the historical assets, actions and records.
    </div>
  );

  return (
    <div className={classes.deleteButtonContainer}>
      <Button
        className={classes.deleteButton}
        onClick={showDeleteDialog}
      >
        Delete the folder
      </Button>
      <Typography variant="body2" className={classes.context}>
        {CONTEXT}
      </Typography>
      <GlobalDialog
        open={openDeleteDialog}
        onClose={closeDeleteDialog}
        closeIcon
        dialogContent={dialogContent}
        cancelText="Cancel"
        onCancel={closeDeleteDialog}
        confirmText="Yes"
        onConfirm={handleCampaignDelete}
      />
    </div>
  );
}

DeleteButton.propTypes = {
  campaignId: PropTypes.string.isRequired,
};
