/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNext';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { deleteAsset } from 'modules/campaign/campaign.services';
import { load, toast } from '../../../util';

const useStyles = makeStyles({
  previewBoxContainer: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  previewBox: {
    width: '50%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mediaCount: {
    height: '',
  },
  mediaRow: {
    flex: 1,
    width: '100%',
    maxHeight: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  mediaNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mediaContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'cover',
    overflow: '',
  },
  imgMedia: {
    height: '100%',
    display: 'block',
    margin: 'auto',
  },
  videoMedia: {
    height: '100%',
    display: 'block',
    margin: 'auto',
  },
  closeIcon: {
    position: 'absolute',
    top: '30px',
    right: '30px',
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  icon: {
    padding: '10px',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
  },
});

export default function AssetPreviewLightBox({
  showResourceCount,
  data,
  onClose,
  startIndex,
}) {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(startIndex);
  const [items, setItems] = useState(data);
  const deletedStatus = items.length !== data.length;

  const previewCurrentMedia = () => {
    let index = currentIndex;
    if (currentIndex >= items.length) index -= 1;

    const currentMedia = items[index];
    if (!currentMedia) return onClose(deletedStatus);

    return currentMedia.materialType.includes('image')
      ? (
        <img
          className={classes.imgMedia}
          alt={`Failed to load ${currentMedia.id}`}
          src={currentMedia.materialUrl}
        />
      ) : (
        <video
          className={classes.videoMedia}
          alt={`Failed to load ${currentMedia.id}`}
          type={currentMedia.materialType}
          src={currentMedia.materialUrl}
          controls
        >
          <track kind="captions" />
        </video>
      );
  };

  const indexInRange = (index) => index >= 0 && index < items.length;

  const showPrevious = () => {
    const current = currentIndex;
    setCurrentIndex(indexInRange(current - 1) ? current - 1 : items.length - 1);
  };

  const showNext = () => {
    const current = currentIndex;
    setCurrentIndex(indexInRange(current + 1) ? current + 1 : 0);
  };

  const handleDeleteAsset = async () => {
    load.open();
    await deleteAsset(items[currentIndex].key);
    const updatedItems = items.filter((item) => item.key !== items[currentIndex].key);

    setItems(updatedItems);
    if (currentIndex >= updatedItems.length) setCurrentIndex(currentIndex - 1);
    load.close();
    toast.success('Delete success.');
  };

  const handleBackdropClick = (event) => {
    if (event.target.id === 'previewBoxContainer') onClose(deletedStatus);
  };

  return (
    <Box
      className={classes.previewBoxContainer}
      id="previewBoxContainer"
      onClick={handleBackdropClick}
      zIndex="modal"
    >
      <Close
        className={classes.closeIcon}
        onClick={() => { onClose(deletedStatus); }}
      />
      <div className={classes.previewBox}>
        {
          showResourceCount && (
            <Typography variant="body1" color="secondary">
              {`${currentIndex + 1} / ${items.length}`}
            </Typography>
          )
        }
        <Grid container className={classes.mediaRow}>
          <Grid item xs={1} className={classes.mediaNav}>
            <NavigateBeforeOutlinedIcon
              className={classes.icon}
              onClick={showPrevious}
            />
          </Grid>
          <Grid item xs={10} className={classes.mediaContainer}>
            {previewCurrentMedia()}
          </Grid>
          <Grid item xs={1} className={classes.mediaNav}>
            <NavigateNextOutlinedIcon
              className={classes.icon}
              onClick={showNext}
            />
          </Grid>
        </Grid>
        <DeleteForeverOutlinedIcon
          className={classes.icon}
          onClick={handleDeleteAsset}
        />
      </div>
    </Box>
  );
}

AssetPreviewLightBox.defaultProps = {
  showResourceCount: true,
  startIndex: 0,
};

AssetPreviewLightBox.propTypes = {
  showResourceCount: PropTypes.bool,
  startIndex: PropTypes.number,
  data: PropTypes.arrayOf.isRequired,
  onClose: PropTypes.func.isRequired,
};
