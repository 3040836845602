import storage, { STORAGE_KEY } from './storage';

const getStorageToken = () => {
  const userInfo = storage.get(STORAGE_KEY.USERINFO, true);
  const { jwtToken, exp } = userInfo;
  if (!exp || exp * 1000 < Date.now()) {
    return null;
  }
  return jwtToken;
};

export default {
  getStorageToken,
};
