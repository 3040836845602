import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import theme from '../../../styles/theme';

const useStyles = makeStyles({
  campaignField: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  titleInput: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  titleText: {
    disableUnderline: true,
    color: '#000000',
  },
  actions: {
    height: '100%',
    marginLeft: '10px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  editButton: {
    textDecoration: 'underline',
  },
  buttonGroup: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  coverImage: {
    backgroundColor: '#D3C4C4',
    height: 32,
    width: 75,
  },
  coverImageFileUpload: {
    '& input': {
      display: 'none',
    },
  },
  uploadButtonRoot: {
    padding: '4px 8px',
    fontSize: '1rem',
    fontWeight: 350,
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    '&.Mui-disabled': {
      backgroundColor: '#fff',
      color: theme.palette.text.disabled,
    },
  },
  uploadButtonDisabled: {},
});

function CampaignInfoField({ campaign, field, onSave = () => { } }) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [campaignDetail, setCampaignDetail] = useState({ ...campaign, coverImageUrl: `/${campaign.coverImageUrl}` });

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleFieldChange = (event) => {
    let { value } = event.target;
    if (field === 'coverImage') {
      [value] = event.target.files;
      const reader = new window.FileReader();

      // TODO: <dod_du@epam.com> validation cover image size < 1MB
      reader.addEventListener('load', () => {
        setCampaignDetail({
          ...campaign,
          coverImage: value,
          coverImageUrl: reader.result,
          coverImageChanged: true,
        });
      }, false);

      if (value) {
        reader.readAsDataURL(value);
      }
      return;
    }
    setCampaignDetail({
      ...campaign,
      [field]: value,
    });
  };

  const save = () => {
    toggleEditMode();
    onSave(campaignDetail);
  };

  const cancel = () => {
    setCampaignDetail({ ...campaign, coverImageUrl: `/${campaign.coverImageUrl}` });
    toggleEditMode();
  };

  /**
     * display cover image for below senarios:
     * - not in editMode and has coverImageUrl
     * - in editMode and new image is selected
     */
  const previewCoverImage = () => {
    let result = true;
    if (editMode) {
      result = !!campaignDetail.coverImageChanged;
    } else {
      result = !!campaignDetail.coverImageUrl;
    }
    return result;
  };

  const fields = {
    title: {
      title: 'Campaign Folder Name',
      input: (
        <TextField
          InputProps={{ disableUnderline: true }}
          value={campaignDetail.title}
          disabled={!editMode}
          onChange={handleFieldChange}
          name="campaignTitle"
        />
      ),
    },
    coverImage: {
      title: 'Folder Cover Image (375 * 150px)',
      input: (
        <label htmlFor="coverImageFileUpload" className={classes.coverImageFileUpload}>
          <input
            accept="image/*"
            id="coverImageFileUpload"
            type="file"
            disabled={!editMode}
            onChange={handleFieldChange}
          />
          {previewCoverImage() && (
            <CardMedia
              className={classes.coverImage}
              image={campaignDetail.coverImageUrl}
            />
          )}
          {!previewCoverImage() && (
            <Button
              classes={{ root: classes.uploadButtonRoot }}
              color="primary"
              component="span"
              disabled={!editMode}
            >
              <ArrowUpward className={classes.arrowUpward} />
              <Typography component="span">Upload Cover Image</Typography>
            </Button>
          )}
        </label>
      ),
    },
  };

  const fieldConfig = fields[field];

  return (
    <Grid container className={classes.campaignField}>
      <Grid xs={11} item className={classes.titleInput}>
        <Typography>{fieldConfig.title}</Typography>
        {fieldConfig.input}
      </Grid>
      <Grid xs={1} item className={classes.actions}>
        {editMode
          ? (
            <div className={classes.buttonGroup}>
              <CheckIcon onClick={save} />
              <CloseIcon onClick={cancel} />
            </div>
          ) : (
            <Button onClick={toggleEditMode}>
              <Typography className={classes.editButton}>Edit</Typography>
            </Button>
          )}
      </Grid>
    </Grid>
  );
}

export default CampaignInfoField;
