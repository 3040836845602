/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Button, Input } from '@material-ui/core';
import { ROUTE_PATH, REQUEST_WEB_PORTAL_ACCESS_EMAIL } from '../../../constants';
import { getUserInfo, getWebPortalInfo } from '../auth.services';
import {
  toast,
  storage,
  STORAGE_KEY,
  load,
} from '../../../util';

const PAGE_CONSTANT = {
  URL_PARAMS: {
    STATE: 'state=',
    CODE: 'code=',
  },
  WX_STATE: 'getCode',
};

const useStyles = makeStyles({
  qrCode: {
    backgroundColor: '#ffffff',
    margin: 'auto',
    marginTop: '20px',
    width: '281px',
    height: '351px',
    padding: '40px 59px 60px 41px',
  },
  scanText: {
    marginTop: '36px',
    fontFamily: 'Burberry Styrene',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '20px',
  },
  requestAccess: {
    marginTop: '13px',
    fontFamily: 'Burberry Styrene',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '20px',
    textDecoration: 'underline',
  },
});

export default function MainContent() {
  const classes = useStyles();
  const history = useHistory();
  const fakeWxCodeRef = useRef(null);
  const [appInfo, setAppInfo] = useState(null);

  const login = async (wxCode) => {
    if (!wxCode) {
      return;
    }
    const useInfo = await getUserInfo(wxCode);
    storage.save(STORAGE_KEY.USERINFO, useInfo);
    history.push(ROUTE_PATH.CAMPAIGNS);
  };

  const localLogin = () => {
    const fakeWxCode = fakeWxCodeRef.current.value;
    login(fakeWxCode);
    load.open();
  };

  const getQrCodeInfo = async () => {
    try {
      const webPortalInfo = await getWebPortalInfo();
      setAppInfo(webPortalInfo);
      load.close();
      window.WwLogin({
        id: 'wx_reg',
        state: PAGE_CONSTANT.WX_STATE,
        ...webPortalInfo,
      });
    } catch (error) {
      load.close();
      toast.error('Login Faild.');
    }
  };

  useEffect(() => {
    load.open();
    if (window.location.search) {
      const paramsString = window.location.search.substring(1);
      const searchParams = new URLSearchParams(paramsString);
      const urlState = searchParams.get('state');
      const wxCode = searchParams.get('code');

      if (!urlState || urlState !== PAGE_CONSTANT.WX_STATE) {
        toast.error('Invaild state.');
        load.close();
        return;
      }
      login(wxCode);
    } else {
      if (appInfo) {
        load.close();
        return;
      }
      getQrCodeInfo();
    }
  });

  return appInfo ? (
    <div>
      <div className={classes.qrCode} id="wx_reg" />
      <div className={classes.scanText}>
        <label>Scan QR code to login Web Portal</label>
      </div>
      <div className={classes.requestAccess}>
        <a href={`mailto:${REQUEST_WEB_PORTAL_ACCESS_EMAIL}?subject=Request WebPortal Access`}>Request WebPortal Access</a>
      </div>
      {process.env.NODE_ENV !== 'production' && (
        <div className={classes.requestAccess}>
          <Input placeholder="Local login code" inputRef={fakeWxCodeRef} inputProps={{ 'aria-label': 'Local login code' }} />
          <Button onClick={localLogin}>Local Login</Button>
        </div>
      )}
    </div>
  ) : (<></>);
}
