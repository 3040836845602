import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Typography } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { ALL_CAMPAIGN_STATUS, CAMPAIGN_STATUS_MAP } from 'modules/campaign/constants';
import { getCampaignListByStatus, getCampaignsStatusOverview } from '../campaign.services';
import CampaignTable from './campaignTable';
import setTabStyles from '../tabStyles';
import {
  load,
  emitter,
  EMITTER_KEY,
} from '../../../util';

const useStyles = makeStyles({
  ...setTabStyles('100px'),
  contentTitle: {
    paddingBottom: '15px',
    height: '30px',
  },
});

export default function MainContent() {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(ALL_CAMPAIGN_STATUS[0]);
  const [campaignList, setCampaignList] = useState([]);

  const refresh = async (currentTabValue) => {
    load.open();
    const currentCampaignList = await getCampaignListByStatus(currentTabValue);
    const overview = await getCampaignsStatusOverview();

    if (currentCampaignList && overview) {
      setCampaignList(currentCampaignList);
      emitter.emit(EMITTER_KEY.LIST_REFRESH_DONE, overview);
    }
    load.close();
  };

  const handleTabSwitch = (event, newValue) => {
    setTabValue(newValue);
    setCampaignList([]);
  };

  useEffect(() => {
    refresh(tabValue);
  }, [tabValue]);

  return (
    <TabContext className={classes.tabContext} value={tabValue}>
      <Typography variant="h2" className={classes.contentTitle}>All assets by campaign</Typography>
      <TabList onChange={handleTabSwitch}>
        {ALL_CAMPAIGN_STATUS.map((status) => (
          <Tab
            className={status === tabValue ? classes.selectedTab : classes.tab}
            label={CAMPAIGN_STATUS_MAP[status]}
            value={status}
            key={status}
          />
        ))}
      </TabList>

      {ALL_CAMPAIGN_STATUS.map((status) => (
        <TabPanel className={classes.tabPanel} value={status} key={`${status}-panel`}>
          <CampaignTable campaignList={campaignList} />
        </TabPanel>
      ))}
    </TabContext>
  );
}
