/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import CampaignRow from './campaignRow';
import LoadMoreButton from '../../loadmoreButton';
import LoadMoreMgr from '../../loadmore.util';

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '100%',
    borderTopRightRadius: '5px',
  },
  headerCell: {
    backgroundColor: '#FFFFFF',
    padding: '30px 10px 30px 30px',
  },
});

const CAMPAIGN_TABLE_ROW_HEADERS = [
  'No.',
  'Cover Preview',
  'Name',
  'Assets Overview',
  'Created by',
  'Created Date',
  'Status',
];

const calcCampaignNo = (index) => {
  if (String(index).length === 1) {
    return `0${index}`;
  }
  return String(index);
};

const campaignLoadMoreMgr = new LoadMoreMgr([], { pageSize: 10 });

export default function CampaignTable({ campaignList }) {
  const classes = useStyles();
  const [campaigns, setCampaigns] = useState({ data: [] });
  useEffect(() => {
    campaignLoadMoreMgr.setData(campaignList);
    setCampaigns(campaignLoadMoreMgr.first());
  }, [campaignList]);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {CAMPAIGN_TABLE_ROW_HEADERS.map((headerItem, index) => (
              <TableCell className={classes.headerCell} key={headerItem}>
                <Typography align={index === 0 ? 'right' : 'left'}>{headerItem}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.data.length > 0 && campaigns.data.map((campaign, index) => (
            <CampaignRow
              campaign={campaign}
              campaignNo={calcCampaignNo(index + 1)}
              key={`campaign-${index}`}
            />
          ))}
        </TableBody>
      </Table>
      {campaigns.hasNext && (
        <LoadMoreButton
          variant="text"
          color="primary"
          onClick={() => { setCampaigns(campaignLoadMoreMgr.next()); }}
        >
          Load more
        </LoadMoreButton>
      )}
    </TableContainer>
  );
}
