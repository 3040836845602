import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Container,
  Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CAMPAIGN_STATUS_MAP, STATUS_COLOR_MAP } from 'modules/campaign/constants';
import { useParams, useHistory } from 'react-router-dom';
import { emitter, EMITTER_KEY } from '../../../util';
import StatusButton from './statusButton';
import DeleteButton from './deleteButton';

const useStyles = makeStyles({
  sideNav: {
    position: 'relative',
    height: '100%',
  },
  backButton: {
    padding: '0',
    marginBottom: '30px',
    fontSize: '1.0rem',
    fontWeight: '550',
    lineHeight: '1.167',
  },
  assetsOverview: {
    height: '80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '20px',
    paddingBottom: '20px',
    borderBottom: '1px solid #C4C4C4',
  },
  campaignStatus: {
    marginBottom: '20px',
    paddingBottom: '20px',
    borderBottom: '1px solid #C4C4C4',
  },
  status: {
    marginTop: '20px',
    color: (props) => STATUS_COLOR_MAP[props.campaignDetail.status],
    display: 'flex',
    alignItems: 'center',
  },
});

export default function SideNav() {
  const [campaignDetail, setCampaignDetail] = useState({});
  const [assetCount, setAssetCount] = useState(0);
  const history = useHistory();
  const classes = useStyles({ campaignDetail });
  const { id: campaignId } = useParams();

  const getCampaignDetail = useCallback(async (campaign) => {
    setCampaignDetail(campaign);
    let count = 0;
    if (campaign.assetsByGroup) {
      campaign.assetsByGroup.forEach((item) => {
        count += item.assets.length;
      });
    }
    setAssetCount(count);
  }, []);

  useEffect(() => {
    emitter.addListener(EMITTER_KEY.DETAIL_REFRESH_DONE, getCampaignDetail);

    return () => {
      emitter.removeListener(EMITTER_KEY.DETAIL_REFRESH_DONE, getCampaignDetail);
    };
  }, [getCampaignDetail]);

  return campaignDetail.assetsByGroup
    ? (
      <Container className={classes.sideNav}>
        <Button
          className={classes.backButton}
          onClick={() => history.go(-1)}
          startIcon={<ArrowBackIosIcon />}
        >
          Back to all
        </Button>
        <div className={classes.assetsOverview}>
          <Typography variant="h3">Assets Overview:</Typography>
          {campaignDetail.assetsByGroup && campaignDetail.assetsByGroup.map((group) => (
            <Typography variant="body1" key={group.groupName}>
              {`${group.groupName}: ${group.assets.length}`}
            </Typography>
          ))}
        </div>
        <div className={classes.campaignStatus}>
          <Typography variant="h3">Campaign Folder Status:</Typography>
          {campaignDetail.status && (
            <Typography className={classes.status}>
              <FiberManualRecordIcon />
              {CAMPAIGN_STATUS_MAP[campaignDetail.status]}
            </Typography>
          )}
        </div>
        <StatusButton
          campaignId={campaignId}
          status={campaignDetail.status}
          assetsCount={assetCount}
        />
        <DeleteButton
          campaignId={campaignId}
        />
      </Container>
    ) : (
      <></>
    );
}
