import { ROUTE_PATH } from 'constants/index';
import SideNav from './sideNav';
import MainContent from './mainContent';

export default {
  public: false,
  path: ROUTE_PATH.CAMPAIGN_DETAIL,
  SideNav,
  MainContent,
};
