/* eslint-disable no-param-reassign */
const storage = window.localStorage;

const save = (key, val) => {
  if (typeof val === 'object') {
    val = JSON.stringify(val);
  }
  storage.setItem(key, val);
};

const get = (key, isObject = false) => {
  const item = storage.getItem(key);

  if (isObject) {
    if (item) return JSON.parse(item);
    return {};
  }

  return item;
};

const remove = (key) => {
  storage.removeItem(key);
};

const clear = () => {
  storage.clear();
};

export default {
  save,
  get,
  remove,
  clear,
};

export const STORAGE_KEY = {
  USERINFO: 'userInfo',
};
