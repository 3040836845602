import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import campaignPages from 'modules/campaign';
import authPages from 'modules/auth';
import { ROUTE_PATH } from 'constants/index';
import RouteGuard from './routeGuard';

const pages = [...authPages, ...campaignPages];

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to={ROUTE_PATH.LOGIN} />
        {pages.map((page) => <RouteGuard exact path={page.path} page={page} key={page.path} />)}
      </Switch>
    </BrowserRouter>
  );
}
