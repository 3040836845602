import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const LoadMoreButton = withStyles({
  root: {
    display: 'block',
    margin: '0 auto',
    textDecoration: 'underline',
  },
})(Button);

export default LoadMoreButton;
