export const ALL_CAMPAIGN_STATUS = ['published', 'wip', 'expired'];

export const STATUS_COLOR_MAP = {
  published: '#DBDFAA',
  wip: '#B4CEEF',
  expired: '#C4C4C4',
};

export const CAMPAIGN_STATUS_MAP = {
  published: 'PUBLISHED',
  wip: 'WORK IN PROGRESS',
  expired: 'EXPIRED',
};

export const CAMPAIGN_STATUS_MAP_CAP = {
  published: 'PUBLISHED',
  wip: 'IN PROGRESS',
  expired: 'EXPIRED',
};

export const SERVICE_STATUS_MAP = {
  WIP: 'wip',
  PUBLISHED: 'published',
  EXPIRED: 'expired',
};

export const API = {
  GET: {
    COUNT: '/api/assets-status-count',
    DETAIL: '/api/assets-library/:id',
  },
  POST: {
    CREATE: '/api/assets-library',
    SEARCH: '/api/assets-libraries',
  },
  PUT: {
    UPDATE_ASSET: '/api/assets-library/:id',
    UPLOAD: '/api/material',
    THUMBNAIL: '/api/thumbnail',
  },
  DELETE: {
    DELETE_CAMPAIGN: '/api/assets-library',
    DELETE_ASSET: '/api/material',
  },
};
