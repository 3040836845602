export default function setTabStyles(top) {
  return {
    tabList: {
      marginTop: '10px',
    },
    tab: {
      width: '250px',
      height: '50px',
      backgroundColor: '#C4C4C4',
      marginRight: '2px',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    selectedTab: {
      width: '250px',
      backgroundColor: '#FFFFFF',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    tabPanel: {
      flex: 1,
      backgroundColor: '#FFFFFF',
      minHeight: `calc(100% - ${top})`,
      padding: '0px',
      borderTopRightRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  };
}
